<template>
  <v-card>
    <v-card-title class="text-h6 mb-3">
      {{ $t("menus.day_off") }}
      <v-spacer></v-spacer>
      <v-btn color="success" @click="save" class="mr-2">
        {{ $t("labels.save") }}
      </v-btn>
      <v-btn color="error" outlined @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <select-warehouse-model
            v-model="item.id_warehouse"
            :label="$t('labels.warehouse')"
            :single-line="false"
            :disabled="!!item.id"
          ></select-warehouse-model>
        </v-col>
        <v-col cols="12" md="6">
          <date-picker-model
            v-model="item.date"
            :label="$t('labels.date')"
            custom-class="c-input-small"
            :single-line="false"
            :disabled="!!item.id"
          ></date-picker-model>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            class="c-input-small"
            v-model="item.is_allow_order_confirm"
            :items="yesNoOptions"
            :placeholder="$t('labels.is_allow_order_confirm')"
            :label="$t('labels.is_allow_order_confirm')"
            dense
            outlined
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            class="c-input-small"
            v-model="item.status"
            :items="yesNoOptions"
            :placeholder="$t('labels.status')"
            :label="$t('labels.status')"
            :single-line="false"
            dense
            outlined
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea
            class="c-input-small"
            v-model="item.note"
            :placeholder="$t('labels.note')"
            :label="$t('labels.note')"
            :single-line="false"
            dense
            outlined
            clearable
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { YES_NO_OPTIONS } from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "DayOffDialog",
  components: {},
  props: {
    updatingItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    item: {},
    isLoading: false,
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  computed: {
    isDisabledBtn() {
      const checkItem = [...this.items].find(
        (item) => !item.id_wp_packaging || !item.quantity
      );
      return !!checkItem;
    },
  },
  watch: {},
  created() {
    this.item = { ...this.updatingItem };
  },
  mounted() {},
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async save() {
      if (this.updatingItem.id) {
        if (!this.item.note || this.item.note == this.updatingItem.note) {
          this.$vToastify.warning("Cập nhật phaỉ thay đổi ghi chú");
          return false;
        }
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post(`/common/v1/day-off-save`, this.item);
        this.isLoading = false;
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
